import useFullscreen from '@rooks/use-fullscreen';
import {
  CSSProperties,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactPlayer from 'react-player/lazy';
import cn from 'classnames';
import Slider from '@material-ui/core/Slider';
import {
  DEFAULT_TIME_HMS,
  increaseViewCount,
  isNumber,
  useMediaQuery,
} from 'common/utils';
import Icon from 'components/common/Icon';
import { secondsToHms } from 'common/utils';
import useStore from 'store/timeline';
import styles from './VideoPlayer.module.scss';
import useGlobalStore from 'store/global';
import {
  MembershipPackageName,
  PostParentKey,
  PostSource,
  PostState,
  PostType,
  User,
} from 'common/interfaces/api';
import { useRouter } from 'next/router';
import PlayerRateOptions from '../PlayerRateOptions';
import Button from 'components/common/Button';
import useFullscreenStatus from 'common/utils/fullscreen';
import { useMemo } from 'react';
import { isGoldMember } from 'common/utils/membership';
import { parentKeyToScreenName, ScreenName } from 'common/utils/pp_tracking';
import useWatchTimeTracking from 'store/watch_time_tracking';
import {
  addClickPostEvent,
  checkIsCreatorPaidPost,
  exCreatorPostLockMessage,
} from 'common/utils/post';
import { MediaType } from 'service/media';
import { track } from '@amplitude/analytics-browser';

export enum AutoplayThreshold {
  Center,
  Top,
}

export type Props = {
  id: number;
  url: string;
  thumbnail: string;
  postAuthor: User;
  autoplayThreshold?: AutoplayThreshold;
  autoplayOnMount?: boolean;
  isLocked?: boolean;
  postState: PostState;
  disableAutoPlayOnFocus?: boolean;
  isPostDetail?: boolean;
  postType: PostType;
  canSeeFreeMinutes?: boolean;
  freeMinutes?: number;
  handleStopFreeTimes?: () => void;
  freeUrl?: string;
  fullDuration?: number;
  parent?: PostParentKey | string;
  postSource?: PostSource;
};

export default function VideoPlayer({
  id,
  url,
  thumbnail,
  postAuthor,
  autoplayThreshold = AutoplayThreshold.Center,
  autoplayOnMount = false,
  isLocked = false,
  disableAutoPlayOnFocus = false,
  isPostDetail = false,
  postType,
  canSeeFreeMinutes = false,
  freeMinutes = 0,
  freeUrl,
  fullDuration,
  parent,
  postSource,
}: Props): JSX.Element {
  const [activeAudioPlayer, setActiveAudioPlayer] = useStore((state) => [
    state.activeAudioPlayer,
    state.setActiveAudioPlayer,
  ]);
  const timebarRef = useRef<HTMLDivElement>(null);
  const mediaControlRef = useRef<HTMLDivElement>(null);
  const setSnackbarMessage = useGlobalStore(
    (state) => state.setSnackbarMessage
  );
  const [isMounted, setIsMounted] = useState(false);
  const router = useRouter();
  const showPaymentDialog = useGlobalStore((state) => state.showPaymentDialog);
  const currentUser = useStore((state) => state.currentUser);
  const setShowMembershipPayDialog = useGlobalStore(
    (state) => state.setShowMembershipPayDialog
  );
  const increaseWatchTimeOfMedia = useWatchTimeTracking(
    (state) => state.increaseWatchTimeOfMedia
  );
  const stopWatchingMedia = useWatchTimeTracking(
    (state) => state.stopWatchingMedia
  );
  const { toggle: toggleFullscreen, isFullscreen } = useFullscreen();
  const playerRef = useRef<ReactPlayer>(null);
  const playerWrapperRef = useRef<HTMLDivElement>(null);
  const [muted, setMuted] = useState(true);
  const [volume, setVolume] = useState(1);
  const [timebarVal, setTimebarVal] = useState(0);
  const [playing, setPlaying] = useState(autoplayOnMount && !isLocked);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [hasEnded, setHasEnded] = useState(false);
  const isMobileScreen = useMediaQuery('(max-width: 767px)');
  const isCenter = autoplayThreshold === AutoplayThreshold.Center;
  const [isHover, setIsHover] = useState(false);
  const [lastClickTime, setLastClickTime] = useState(-1);
  const freeTimesRef = useRef<HTMLDivElement>(null);
  const [remainingFreeSeconds, setRemainingFreeSeconds] = useState(0);
  const [playedFreeTimes, setPlayedFreeTimes] = useState(0);
  const [isFullScreenBrowser] = useFullscreenStatus();
  const [isReady, setIsReady] = useState(false);
  // Stop playing audio when other audio sources starts playing
  // Note: how is this working?? why don't playing and muted become stale values??
  useEffect(() => {
    if (activeAudioPlayer?.playing && playing && !muted) {
      setMuted(true);
    }
  }, [activeAudioPlayer, playing, muted]);

  const onStart = useCallback(() => {
    if (!playerRef.current) return;
    if (!isLocked && playedFreeTimes > 0) {
      playerRef.current.seekTo(playedFreeTimes);
      setPlayedFreeTimes(0);
    }
  }, [isLocked, playedFreeTimes]);

  useEffect(() => {
    setPlayedFreeTimes(0);
    setPlayedSeconds(0);
    setPlaying(autoplayOnMount && !isLocked);
    return () => {
      if (isPostDetail) {
        stopWatchingMedia(id);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isPostDetail) return;
    if (!canSeeFreeMinutes || freeMinutes === 0 || !showPaymentDialog) {
      setRemainingFreeSeconds(0);
      return;
    }
    const rmFreeSecs = Math.round(
      Math.max(0, Math.min(freeMinutes * 60, duration) - playedSeconds)
    );
    setRemainingFreeSeconds(rmFreeSecs);
    if (rmFreeSecs === 1) {
      setPlayedFreeTimes(0);
      if (playerRef.current) {
        playerRef.current.seekTo(0);
      }
      setTimeout(() => {
        setPlaying(false);
      }, 1000);
      showPaymentDialog({ creator: postAuthor });
    }
  }, [
    canSeeFreeMinutes,
    duration,
    freeMinutes,
    playedSeconds,
    postAuthor,
    showPaymentDialog,
    isPostDetail,
  ]);

  // Autoplay
  const intersectionCb: IntersectionObserverCallback = useCallback(
    (entries) => {
      let aTimeout, bTImeout;
      if (disableAutoPlayOnFocus) return;
      const target = entries[0];
      if (target && target.isIntersecting) {
        // CENTER: entering the middle 10% of screen height
        // TOP: over 30% of video is shown and user has not intentionally paused the video
        if (!isLocked) {
          aTimeout = setTimeout(() => {
            const light = playerWrapperRef.current?.querySelector(
              '.react-player__preview'
            );
            if (light) {
              (light as HTMLDivElement).click();
            }
          }, 500);
          bTImeout = setTimeout(() => {
            setPlaying(true);
          }, 2000);
        }
      } else {
        // CENTER: leaving the middle 10% of screen height
        // TOP: under 30% of video is shown
        setPlaying(false);
        if (playerRef.current) {
          // This is the key to keeping the player in light mode
          playerRef.current.showPreview();
        }
      }
      return () => {
        aTimeout && clearTimeout(aTimeout);
        bTImeout && clearTimeout(bTImeout);
      };
    },
    [disableAutoPlayOnFocus, isLocked]
  );
  useEffect(() => {
    if (isLocked || isFullscreen || !isMounted) return;
    const ref = playerWrapperRef.current;
    const options = {
      root: null,
      rootMargin: isCenter ? '-45% 0px -45%' : '0px',
      threshold: isCenter ? 0 : 0.3,
    };
    // Create observer
    const observer = new IntersectionObserver(intersectionCb, options);
    // observe the audio player
    if (ref) {
      observer.observe(ref);
    }
    // clean up on willUnMount
    return () => {
      if (ref) {
        observer.unobserve(ref);
      }
    };
    // Note: for some reasone playerWrapperRef.current needs to be in the dependency array
    // otherwise initial target.isIntersecting will always be false
  }, [
    isCenter,
    intersectionCb,
    isLocked,
    playerWrapperRef,
    isFullscreen,
    isMounted,
  ]);

  // Rewind and fast forward with
  // - positivie skipTime means fast forward
  // - negative skipTime means rewind
  const handleTimeSkip = useCallback(
    (skipTime: number) => {
      if (isLocked) return;
      if (!playerRef.current) return;
      if (skipTime < 0) {
        increaseViewCount(id);
      }
      let newTime = playedSeconds + skipTime;
      if (newTime > duration) newTime = duration - 1;
      if (newTime < 0) newTime = 0;
      setPlayedSeconds(newTime);
      playerRef.current.seekTo(newTime, 'seconds');
      setTimeout(() => {
        if (newTime === duration) {
          setHasEnded(true);
        }
      }, 500);
    },
    [duration, id, isLocked, playedSeconds]
  );

  // Media control overlay
  const mediaControlBtn = useMemo(() => {
    let btn: JSX.Element;
    if (hasEnded) {
      btn = (
        <div className={styles.replayWrapper} onClick={() => setPlaying(true)}>
          <Icon name="replay" width={96} height={96} />
          <div className={styles.replayText}>最初から見る</div>
        </div>
      );
    } else if (!playing) {
      btn = (
        <div className={styles.playWrapper}>
          <div className={styles.skipBtn}>
            <Icon
              name="play-rewind"
              height={50}
              width={50}
              onClick={() => handleTimeSkip(-15)}
            />
          </div>
          <div className={styles.centerBtn}>
            <Icon
              name="play"
              width={96}
              height={96}
              onClick={() => setPlaying(true)}
            />
          </div>
          <div className={styles.skipBtn}>
            <Icon
              name="play-forward"
              height={50}
              width={50}
              onClick={() => handleTimeSkip(15)}
            />
          </div>
        </div>
      );
    } else if (!isFullscreen && playing) {
      btn = (
        <div className={cn(styles.playWrapper, styles.pauseBtn)}>
          <div className={styles.skipBtn}>
            <Icon
              name="play-rewind"
              height={50}
              width={50}
              onClick={() => handleTimeSkip(-15)}
            />
          </div>
          <div className={styles.centerBtn}>
            <Icon
              name="pause"
              width={96}
              height={96}
              onClick={() => setPlaying(false)}
            />
          </div>
          <div className={styles.skipBtn}>
            <Icon
              name="play-forward"
              height={50}
              width={50}
              onClick={() => handleTimeSkip(15)}
            />
          </div>
        </div>
      );
    }
    return btn;
  }, [handleTimeSkip, hasEnded, isFullscreen, playing]);

  const handleSpeakerBtnClick: MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      setMuted((muted) => !muted);
      if (playing && muted) {
        setActiveAudioPlayer('video', false);
        track('Turn On Video Volume');
      }
    },
    [muted, playing, setActiveAudioPlayer]
  );

  // Volume
  const handleVolumeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, newVolume: number | number[]) => {
      e.stopPropagation();
      if (isLocked) return;
      if (!isNumber(newVolume) || Array.isArray(newVolume)) {
        newVolume = 0.5;
      }
      if (newVolume < 0.025) {
        setMuted(true);
      } else {
        setMuted(false);
        setVolume(newVolume);
        if (playing) {
          setActiveAudioPlayer('video', false);
        }
      }
    },
    [isLocked, playing, setActiveAudioPlayer]
  );

  // Timebar
  const handleTimebarChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      if (isLocked) return;
      if (!playerRef.current) return;
      const newTimebarVal = Number(e.target.value);
      playerRef.current.seekTo(newTimebarVal, 'fraction');
      // support seek for only case watch full version => do need use fullDuration
      setPlayedSeconds(newTimebarVal * duration);
      setTimebarVal(newTimebarVal);
      setTimeout(() => {
        if (newTimebarVal === 1) {
          setHasEnded(true);
        }
      }, 500);
    },
    [duration, isLocked]
  );

  useEffect(() => {
    setTimebarVal(duration === 0 ? 0 : playedSeconds / duration);
  }, [playedSeconds, setTimebarVal, duration]);

  const handleClickFullscreen: MouseEventHandler = (e) => {
    e.stopPropagation();
    if (isLocked) return;
    track('See Full Screen');
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (document.exitFullscreen && (document as any).mozFullScreen) {
        void document.exitFullscreen();
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}

    if (!isMobileScreen) {
      void toggleFullscreen(playerWrapperRef.current);
      return;
    }
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    const elem = (playerRef.current?.wrapper as HTMLDivElement)?.querySelector(
      'video'
    );
    // @ts-ignore
    if (elem && elem.webkitEnterFullScreen) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      elem.webkitEnterFullScreen();
    } else void toggleFullscreen(playerWrapperRef.current);
    /* eslint-enable @typescript-eslint/ban-ts-comment */
  };

  const stopPropagation: MouseEventHandler = (e) => {
    e.stopPropagation();
  };

  const handleOverlayClick: MouseEventHandler = async (e) => {
    stopPropagation(e);
    if (!isPostDetail) {
      addClickPostEvent({
        postId: id,
        screen: parentKeyToScreenName(parent) as ScreenName,
        postSource: postSource,
        clickPosition: 'media_view',
        postType: postType,
        mediaType: MediaType.Video,
      });

      await router.push(`/${postAuthor.username}/posts/${id}`);
      return;
    }
    if (isLocked) {
      handleTrialClick();
    } else {
      if (freeTimesRef?.current?.contains(e.target as HTMLElement)) return;
      const selection = window.getSelection();
      if (
        selection.type != 'Range' &&
        !timebarRef?.current?.contains(e.target as HTMLElement) &&
        !mediaControlRef?.current?.contains(e.target as HTMLElement)
      ) {
        setPlaying((playing) => !playing);
        if (!playing) {
          setActiveAudioPlayer('video', false);
          increaseViewCount(id);
        }
      }
    }
  };

  const hms = useMemo(() => {
    return duration > 0 && duration >= playedSeconds
      ? secondsToHms(playedSeconds)
      : DEFAULT_TIME_HMS;
  }, [duration, playedSeconds]);

  const totalHms = useMemo(() => {
    return secondsToHms(canSeeFreeMinutes ? fullDuration : duration);
  }, [canSeeFreeMinutes, duration, fullDuration]);

  const reactPlayerStyle = useMemo(() => {
    let style: CSSProperties;
    if (isFullscreen || isFullScreenBrowser) {
      style = {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      };
    } else {
      style = {};
    }
    return style;
  }, [isFullScreenBrowser, isFullscreen]);

  const handleTrialClick = () => {
    if (postType === PostType.Gold && !isGoldMember(currentUser)) {
      setShowMembershipPayDialog({
        open: true,
        showMembershipStatus: false,
        initPackage: MembershipPackageName.Platinum,
        screen: ScreenName.PostDetail,
      });
      return;
    }
    if (!postAuthor) return;
    // in case of value is undefined, still can open payment dialog
    if (
      postAuthor.is_open_for_subscription === false &&
      checkIsCreatorPaidPost(postType)
    ) {
      setSnackbarMessage({
        type: 'error',
        text: exCreatorPostLockMessage(postType),
      });
      return;
    }
    showPaymentDialog({ creator: postAuthor });
  };

  useEffect(() => {
    if (isMobileScreen && !isLocked) return;
    if (isHover && !isLocked) {
      const light = playerWrapperRef.current?.querySelector(
        '.react-player__preview'
      );
      if (light) {
        (light as HTMLDivElement).click();
      }
    }
  }, [isHover, isLocked, isMobileScreen]);

  useEffect(() => {
    if (isMobileScreen && !isLocked) return;
    const timeOut = setTimeout(() => {
      const img = new Image();
      img.onload = function () {
        if (!playerWrapperRef.current) return;
        const light = playerWrapperRef.current.querySelector(
          '.react-player__preview'
        );
        const wrapperWidth = playerWrapperRef.current.clientWidth;
        if (light) {
          (light as HTMLDivElement).style.minHeight = `${
            (wrapperWidth / img.width) * img.height
          }px`;
        }
      };
      img.src = thumbnail;
    }, 600);
    return () => clearTimeout(timeOut);
  }, [thumbnail, isMobileScreen, isLocked]);

  return (
    <div
      className={cn(styles.playerWrapper, {
        [styles.fullscreenVideo]: isFullscreen || isFullScreenBrowser,
      })}
      ref={playerWrapperRef}
      onMouseLeave={() => setIsHover(false)}
      onMouseEnter={() => setIsHover(true)}
    >
      <ReactPlayer
        width="100%"
        height="100%"
        style={reactPlayerStyle}
        ref={playerRef}
        url={canSeeFreeMinutes && freeUrl ? freeUrl : url}
        muted={!isPostDetail || muted}
        volume={volume}
        playsinline
        playing={playing && isReady && isMounted}
        playbackRate={playbackRate}
        onError={() => {
          if (!isReady) return;
          setTimeout(() => {
            if (playerRef.current && playedSeconds < 10) {
              playerRef.current.seekTo(playedSeconds + 2);
            }
          }, 500);
        }}
        onReady={() => {
          setIsReady(true);
          if (isPostDetail) {
            track('Start Play Video In PostDetail');
          }
        }}
        onProgress={({ playedSeconds }) => {
          if (isPostDetail) {
            increaseWatchTimeOfMedia(id);
          }
          setPlayedSeconds(playedSeconds);
          if (playedSeconds < duration - 1 && hasEnded) {
            setHasEnded(false);
          }
          if (canSeeFreeMinutes) {
            setPlayedFreeTimes(playedSeconds);
          }
        }}
        onDuration={(duration) => setDuration(duration)}
        onPlay={() => {
          setHasEnded(false);
        }}
        onEnded={() => {
          setHasEnded(true);
          setPlaying(false);
        }}
        onStart={onStart}
        light={
          (isMobileScreen || disableAutoPlayOnFocus) && !isLocked
            ? false
            : thumbnail
        }
        config={{
          file: {
            attributes: {
              poster: thumbnail,
            },
          },
        }}
      ></ReactPlayer>
      <div
        className={cn(styles.playerOverlay, {
          [styles.fullscreen]: isFullscreen || isFullScreenBrowser,
          [styles.notPostDetail]: !isPostDetail,
        })}
        onClick={handleOverlayClick}
      >
        {canSeeFreeMinutes && isPostDetail && (
          <div className={styles.remainingFreeTime} ref={freeTimesRef}>
            <span>
              <span className={styles.time}>
                {secondsToHms(Math.max(remainingFreeSeconds, 0))}
              </span>
              残りの無料時間
            </span>
            <Button
              text="プライム登録を無料体験"
              onClick={(e) => {
                showPaymentDialog({ creator: postAuthor });
                if (isFullscreen || isFullScreenBrowser) {
                  handleClickFullscreen(e);
                }
              }}
            />
          </div>
        )}

        <div className={styles.mediaControlBtn} ref={mediaControlRef}>
          {mediaControlBtn}
        </div>
        <div
          className={cn(styles.leftCorner, {
            [styles.mobileCtrlLeftCorner]: isMobileScreen,
          })}
        >
          {duration > 0 && (
            <div className={styles.playTime}>
              {hms} / {totalHms}
            </div>
          )}
          {!canSeeFreeMinutes && (
            <div className={styles.timebar} ref={timebarRef}>
              <input
                key={`video-time-slider-${id}`}
                type="range"
                min={0}
                max={1}
                step={0.025}
                value={timebarVal}
                style={{
                  background: `linear-gradient(to right, #fff 0%, #fff ${
                    timebarVal * 100
                  }%, #aeaeae ${timebarVal * 100}%, #aeaeae 100%)`,
                }}
                onChange={handleTimebarChange}
                className={styles.sliderTime}
                onMouseDown={() => {
                  setLastClickTime(timebarVal);
                }}
                onMouseUp={() => {
                  // call event increase view count
                  if (lastClickTime > timebarVal) {
                    increaseViewCount(id);
                  }
                  setLastClickTime(-1);
                }}
              />
            </div>
          )}
        </div>
        <div className={styles.rightCorner}>
          <div className={styles.volumeCtrl} onClick={stopPropagation}>
            {!isMobileScreen && (
              <Slider
                key={`video-slider-${id}`}
                orientation="vertical"
                defaultValue={1}
                min={0}
                max={1}
                step={0.025}
                onChange={handleVolumeChange}
                className={styles.volumeSlider}
              />
            )}
            <div className={styles.speakerBtn} onClick={handleSpeakerBtnClick}>
              <Icon name="speaker" width={26} height={26} hasOn isOn={!muted} />
            </div>
          </div>

          <div className={styles.speedCtrl} onClick={stopPropagation}>
            <div className={styles.speedCtrlOptions}>
              <PlayerRateOptions
                setPlaybackRate={setPlaybackRate}
                clickCallback={() => {
                  try {
                    console.log(isFullScreenBrowser);
                    if (isFullscreen || isFullScreenBrowser) {
                      if (
                        document.exitFullscreen &&
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        (document as any).mozFullScreen
                      ) {
                        void document.exitFullscreen();
                        return;
                      }
                      void toggleFullscreen();
                    }
                    // eslint-disable-next-line no-empty
                  } catch (error) {}
                }}
              />
            </div>
            <div className={styles.speedBtn}>{playbackRate}x</div>
          </div>

          <div className={styles.fullscreenBtn} onClick={handleClickFullscreen}>
            <div>
              <Icon name="fullscreen" width={17} height={17} />
            </div>
            {!isMobileScreen && (
              <div className={styles.fullscreenText}>
                {isFullscreen ? '全画面を閉じる' : '全画面で見る'}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
